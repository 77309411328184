import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { AddRecord } from '@components/ActionButtons';
import { BoxBodyContainer, BoxHeaderTitle } from '@components/Box';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { routes } from '@config/routes';

export const Invoice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const baseBreadCrumbs = [{ link: 'Revenue and Collections', to: '' }];

  const isOnHomePage = location.pathname === routes.RNC_INVOICES;

  const breadCrumbLinks = isOnHomePage
    ? baseBreadCrumbs
    : [...baseBreadCrumbs, { link: 'Invoice', to: routes.RNC_INVOICES }];

  return (
    <>
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="text-sm font-semibold text-gray-500">
            Revenue and Collections
          </div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="text-2xl font-black">Invoice</div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="">
              {isOnHomePage && (
                <div className="flex flex-row space-x-1">
                  <AddRecord
                    label="Create Invoice"
                    onClick={() => {
                      navigate(`${routes.RNC_INVOICES}/create`);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>
    </>
  );
};
