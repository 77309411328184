interface IProps {
  children: React.ReactNode;
}

export const BoxHeaderTitle: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <div className="flex gap-0 items-end">
        <div className="max-w-max pl-2 pr-6 py-4 bg-white rounded-tr-full">
          {children}
        </div>

        <div className="relative -ml-1 bg-white max-w-max">
          <div className="w-8 h-8 rounded-bl-full bg-gray-100" />
        </div>
      </div>
    </>
  );
};
