import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  EditSettingsModel,
  Grid,
  DataStateChangeEventArgs,
  DataResult,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { FilterRecord } from '@components/Grid/FilterRecord';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { TableActions } from '@components/Table/TableActions';
import { routes } from '@config/routes';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { CustomerResultViewModel } from '@models/Customer';
import { CustomerService } from '@services/Customer';

export const CustomerList: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);
  const [showDelete, setShowDelete] = useState(false);
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return CustomerService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const deleteRecord = (id: number) => {
    CustomerService.delete(id).then((response) => {
      if (response.status == 204) {
        console.log('Successfully deleted the record.');
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const handleDelete = async (rowId: any) => {
    try {
      CustomerService.getById(Number(rowId))
        .then((response) => response.data as Promise<CustomerResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setSelectedCustomerId(rowId);
            setCustomerName(result.data.companyName);
            setShowDelete(true);
          } else {
            const message = `Error: Failed to fetch category details for deletion. ${result.errorMessage}`;
            alert(message);
            console.log(message);
          }
        });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleEdit = (rowId: any) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}/edit`);
  };

  const handleView = (rowId: any) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}`);
  };

  const handleSendStatement = (rowId: number) => {
    console.log('Send statement to customer', rowId);
    alert('Send statement to customer');
  };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;
    return (
      <TableActions
        dropdownActions={[
          {
            title: 'Send Statement',
            onClick: () => handleSendStatement(props.id),
          },
          {
            title: 'View',
            onClick: () => handleView(props.id),
          },
          {
            title: 'Edit',
            onClick: () => handleEdit(props.id),
          },
          {
            showTopSeparator: true,
            title: 'Delete',
            onClick: () => handleDelete(props.id),
          },
        ]}
        menuOnTop={menuOnTop}
      />
    );
  };

  console.log('data', data);

  return (
    <>
      <FilterRecord
        label="Filter by Name"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        onFilter={() => {
          setDataUrlQueryFilter(`&nameFilters=${nameFilter}`);
        }}
        onClear={() => {
          setDataUrlQueryFilter('');
          setNameFilter('');
        }}
      />

      <Space />

      <GridComponent
        dataBound={renderComplete}
        dataSource={data}
        ref={(g) => (grid = g)}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        editSettings={editOptions}
        dataStateChange={dataStateChange}
      >
        <ColumnsDirective>
          <ColumnDirective field="companyName" headerText="Name" />
          <ColumnDirective field="primaryContact.email" headerText="Email" />
          <ColumnDirective field="primaryContact.phone" headerText="Phone" />

          <ColumnDirective
            headerText="Actions"
            width={140}
            template={actionButtons}
          />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>

      {showDelete && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setShowDelete(false)} />
            <Box size="medium">
              <>
                <div className="px-4">
                  <span>Are you sure to delete Customer: {customerName}</span>
                </div>
                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowDelete(false)} />
                    <OkButton
                      label="Delete"
                      onClick={() => deleteRecord(selectedCustomerId)}
                    />
                  </div>
                </FormButtonsContainer>
              </>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
