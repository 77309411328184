import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EstimateAndQuotePDF } from './EstimateAndQuotePDF';

import { DownloadIcon } from '@components/ButtonSwitchContainer/React-Icon/DownloadIcon';
import { Simpacc } from '@components/ButtonSwitchContainer/React-Icon/Simpacc';
import { FormButtonsContainer } from '@components/Container';
import {
  DetailTableHeaderLeft,
  DetailTableHeaderCenter,
  DetailTableHeaderRight,
  DetailTableDataLeft,
  DetailTableDataCenter,
  DetailTableDataRight,
} from '@components/Details';
import { OkButton } from '@components/Form/OkButton';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import { routes } from '@config/routes';
import {
  BillingAddressResultViewModel,
  billingAddressDefaultValue,
} from '@models/BillingAddress';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import {
  EstimateAndQuoteResultViewModel,
  estimateAndQuoteDefaultValue,
} from '@models/EstimateAndQuote';
import { EstimateAndQuoteDetailViewModel } from '@models/EstimateAndQuoteDetail';
import { EstimateAndQuoteDetailSalesTaxViewModel } from '@models/EstimateAndQuoteDetailSalesTax';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { EstimateAndQuoteService } from '@services/EstimateAndQuote';
import { formatAsCurrency } from '@utils/numberFormat';
import { toTitleCase } from '@utils/titleCase';

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const EstimateAndQuoteDetailsModal = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState(estimateAndQuoteDefaultValue);

  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_ESTIMATES;

  const [customer, setCustomer] = useState(customerDefaultValue);

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const [estimateDetails, setEstimateDetails] = useState<
    EstimateAndQuoteDetailViewModel[]
  >([]);

  const loadEstimateDetails = () => {
    EstimateAndQuoteService.getEstimateDetails(Number(id)).then((result) => {
      setEstimateDetails(result.data);
    });
  };

  const loadData = () => {
    EstimateAndQuoteService.getById(Number(id))
      .then(
        (response) => response.data as Promise<EstimateAndQuoteResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          setData(result.data);

          if (result.data.billingAddressID) {
            BillingAddressService.getById(result.data.billingAddressID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => setCustomerAddress(addressResult.data));
          }

          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomer(customerResult.data);
              });
          }

          console.log('Success: Fetched the record');
        } else {
          console.log(
            `Error: Failed to get the record. ${result.errorMessage}`
          );
        }
      });
  };

  console.log('data:', data);

  useEffect(() => {
    if (id) {
      loadData();
      loadEstimateDetails();
    }
  }, [id]);

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = estimateDetails.map((o) =>
      o.estimateDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({
          ...salesTaxes,
          salesTaxKey: `${salesTaxes.salesTaxAbbreviation} (${salesTaxes.rate}%)`,
        }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxKey'
    ) as EstimateAndQuoteDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('pdf-content');
    if (element) {
      html2pdf()
        .from(element)
        .set({
          margin: 0.3,
          filename: `Estimate No: ${data.estimateNumber}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .save()
        .then(() => {
          console.log('PDF successfully downloaded.');
        })
        .catch((error: any) => {
          console.error('Error during PDF generation:', error);
        });
    } else {
      console.error('No content found to download as PDF.');
    }
  };

  return (
    <>
      <div className="flex justify-end pb-4">
        <button
          className="flex gap-x-2 bg-primary-500 rounded-md text-white hover:bg-primary-700 px-4 py-2"
          onClick={() => handleDownloadPDF()}
        >
          Download PDF
          <DownloadIcon height={24} width={24} />
        </button>
      </div>
      <div className="border pb-6 px-4">
        <div className="bg-white">
          <div className="p-4 flex justify-between ">
            <div className="flex items-center">
              <Simpacc height="75" width="50" />
            </div>
            <div className="text-md font-semibold text-end text-gray-500">
              ESTIMATE
              <div className="text-black text-base">
                Tuos Development <br />
                <span className="text-xs">Canada</span>
              </div>
            </div>
          </div>

          <div className="border-t-2 border-gray-700  py-2 px-4">
            <div className="flex grid-cols-2 justify-between">
              <div className="col-span-1">
                <div className="text-base text-gray-500 font-medium">
                  Bill to
                </div>
                <div className="text-base font-medium uppercase">
                  {customer.companyName}
                </div>
                <div className="text-base pb-2">
                  <span className="uppercase">
                    {customer.primaryContact?.firstname}{' '}
                    {customer.primaryContact?.lastname}
                  </span>
                  <br />
                  <span className="text-base">
                    {toTitleCase(
                      `${customerAddress.address1} ${customerAddress.address2}`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.city}, ${customerAddress.state} ${customerAddress.postalCode}`
                    )}
                    <br />
                    {toTitleCase(`${customerAddress.country}`)}
                    <br />
                  </span>
                  <br />
                  {customer.primaryContact?.phone}
                  <br />
                  {customer.primaryContact?.email}
                </div>
              </div>
              <div className="col-span-1 space-y-0.5">
                <div className="font-medium text-base w-full flex items-center justify-between space-x-4 pl-2 pr-1 mr-1">
                  <div>Estimate Number:</div>
                  <div className="font-normal text-base text-end">
                    {data.estimateNumber}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-base w-full flex items-center justify-between space-x-4 pl-2 pr-1">
                  <div>Estimate Date:</div>
                  <div className="font-normal text-base text-end">
                    {formatDate(data.date)}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-base w-full flex items-center justify-between space-x-4 pl-2 pr-1">
                  <div>Valid until:</div>
                  <div className="font-normal text-base text-end">
                    {formatDate(data.dueDate)}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-base w-full flex items-center justify-between space-x-4 pl-2 pr-1 border-gray-600 pt-1 pb-2 border-b-4">
                  <div>Grand Total(CAD):</div>
                  <div className="font-normal text-base text-end">
                    {formatAsCurrency(
                      estimateDetails.reduce(
                        (acc, cur) => acc + cur.subTotal,
                        0
                      ) +
                        estimateDetails
                          .map((o) =>
                            o.estimateDetailSalesTaxes.reduce(
                              (acc, cur) => acc + cur.salesTaxAmount,
                              0
                            )
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="max-w-5x1 mx-auto">
              <TableContainer>
                <Table>
                  <thead className="bg-primary-100">
                    <tr>
                      <DetailTableHeaderLeft>Item</DetailTableHeaderLeft>
                      <DetailTableHeaderCenter>
                        Quantity
                      </DetailTableHeaderCenter>
                      <DetailTableHeaderRight>Price</DetailTableHeaderRight>
                      <DetailTableHeaderRight>Amount</DetailTableHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    {estimateDetails.map((row, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? ' bg-white' : 'bg-green-50'
                        }`}
                      >
                        <DetailTableDataLeft>
                          <div className="pl-2 ">
                            <div className="font-semibold text-sm text-start uppercase">
                              <TextWrapper text={row.product?.name || ''} />
                            </div>
                          </div>
                        </DetailTableDataLeft>
                        <DetailTableDataCenter>
                          <span className="font-medium text-sm">
                            {row.quantity}
                          </span>
                        </DetailTableDataCenter>
                        <DetailTableDataRight>
                          <span className="font-medium text-sm">
                            {formatAsCurrency(row.price)}
                          </span>
                        </DetailTableDataRight>
                        <DetailTableDataRight>
                          <span className="font-medium text-sm">
                            {formatAsCurrency(row.subTotal)}
                          </span>
                        </DetailTableDataRight>
                      </tr>
                    ))}

                    <Space />

                    <tr>
                      <td colSpan={6}>
                        <div className="space-y-1">
                          <div className="flex justify-end mr-6 text-sm">
                            <div className="font-bold mr-4">SubTotal:</div>

                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                estimateDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                )
                              )}
                            </div>
                          </div>

                          {Object.entries(getSalesTaxesBySalesTaxID()).map(
                            ([taxTitle, details]) => (
                              <div
                                className="flex justify-end mr-6 text-sm"
                                key={taxTitle}
                              >
                                <div className="font-medium mr-4">
                                  {taxTitle}
                                </div>

                                <div className="w-24 text-right">
                                  {formatAsCurrency(
                                    details.reduce(
                                      (acc, cur) => acc + cur.salesTaxAmount,
                                      0
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}

                          <div className="flex justify-end mr-6 pt-2 pl-96 text-sm">
                            <div className="w-full ml-80 flex justify-end border-t border-gray-600 py-2 border-b-4">
                              <div className="font-bold mr-4">Total Tax:</div>
                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  estimateDetails
                                    .map((o) =>
                                      o.estimateDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end mr-6 text-base py-4">
                            <div className="font-bold mr-4">Grand Total:</div>
                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                estimateDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                ) +
                                  estimateDetails
                                    .map((o) =>
                                      o.estimateDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>

      <FormButtonsContainer>
        <div className="space-x-2">
          <OkButton
            onClick={() => navigate(previousLocation)}
            // onClick={() => navigate(`${routes.RNC_ESTIMATES}/${id}`)}
            label="Close"
          />
        </div>
      </FormButtonsContainer>

      {/* pdf Export */}
      <EstimateAndQuotePDF estimateAndQuotesId={data.id} />
    </>
  );
};
