interface Props {
  onClick?: () => void;
  // label: string;
}

export const OkButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm bg-primary-500  text-white hover:bg-primary-700"
    >
      {label}
    </button>
  );
};
