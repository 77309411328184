import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { CustomerViewModel } from './Customer';
import { EstimateAndQuoteDetailViewModel } from './EstimateAndQuoteDetail';

export const estimateAndQuoteValidation = Yup.object().shape({
  customerID: Yup.number().required('Customer field is Required'),
  contactID: Yup.number().required('Contact field is Required'),
  shippingAddressID: Yup.number().required(
    'Shipping Address field is Required'
  ),
  billingAddressID: Yup.number().required('Billing Address field is Required'),
  estimateNumber: Yup.string()
    .max(20, 'Estimate Number exceeds the maximum length of 20 characters')
    .nullable(),
  customerRef: Yup.string().max(
    20,
    'Customer Ref exceeds the maximum length of 20 characters'
  ),
  notes: Yup.string()
    .max(200, 'Notes exceeds the maximum length of 200 characters')
    .nullable(),
  date: Yup.date().required('Date field is required'),
  dueDate: Yup.date()
    .when(
      'date',
      (date, Yup) => date && Yup.min(date, 'Due date must be after date')
    )
    .required('Due Date field is required'),
});

export interface EstimateAndQuoteViewModel {
  id: number;
  estimateNumber: string;
  customerRef: string;
  date: string;
  dueDate: string;
  notes: string;
  discount: number;
  status: number;
  customer?: CustomerViewModel;
  customerID?: number;
  contactID: number;
  contactEmail: string;
  contactPhone: string;
  shippingAddressID: number;
  billingAddressID: number;
  isActive: true;
  estimateDetails: EstimateAndQuoteDetailViewModel[];
}

export interface EstimateAndQuoteVwViewModel {
  id: number;
  estimateNumber: string;
  status: number;
  statusDisplayString?: string;
  customerID: number;
  customerCompanyName: string;
  subTotal?: number;
  taxAmount?: number;
  total?: number;
}

export interface EstimateAndQuoteTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: EstimateAndQuoteVwViewModel[];
}

export interface EstimateAndQuoteResultViewModel extends ResultViewModel {
  data: EstimateAndQuoteViewModel;
}

export interface ConvertToInvoiceViewModel {
  newInvoiceId: number;
}

export interface ConvertToInvoiceResultViewModel extends ResultViewModel {
  data: ConvertToInvoiceViewModel;
}

// Estimates and Quotes Default Values
export const estimateAndQuoteDefaultValue: EstimateAndQuoteViewModel = {
  id: 0,
  estimateNumber: '',
  customerRef: '',
  date: '',
  dueDate: '',
  notes: '',
  discount: 0,
  status: 1,
  customer: undefined,
  customerID: 0,
  contactID: 0,
  contactEmail: '',
  contactPhone: '',
  shippingAddressID: 0,
  billingAddressID: 0,
  isActive: true,
  estimateDetails: [],
};

export const estimateAndQuoteTableDefaultValue: EstimateAndQuoteTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };

export const getStatusText = (statusValue: number): any => {
  const statusOption = estimateStatusSelectOptions.find(
    (option) => option.value === statusValue.toString()
  );
  return statusOption ? statusOption.text : '';
};

export const estimateStatusSelectOptions = [
  {
    value: '1',
    text: 'Draft',
  },
  {
    value: '2',
    text: 'Approve',
  },
  {
    value: '3',
    text: 'Email Sent',
  },
  {
    value: '4',
    text: 'Invoiced',
  },
  {
    value: '5',
    text: 'Voided',
  },
];
