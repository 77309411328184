import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';

export const Inventory = () => {
  return (
    <>
      <PageContainer>
        <BreadCrumbs
          links={[
            { link: 'Revenue and Collections', to: `` },
            { link: 'Inventory', to: `${routes.REVENUE_INVENTORY}` },
          ]}
        />
        {/* change this to main content */}
        <HeaderContainer>This is Inventory Page</HeaderContainer>
        {/* change this to main content */}
      </PageContainer>
    </>
  );
};
