import { Link } from 'react-router-dom';

interface Props {
  to: string;
  className: any;
  onClick: any;
  children?: React.ReactNode;
}

export const DropDownMenuItems: React.FC<Props> = ({
  to,
  children,
  onClick,
  className,
}) => {
  return (
    <div className="px-2">
      <div className={className}>
        <span className="w-20" />
        <Link to={to} className="w-full" onClick={onClick}>
          {children}
        </Link>
      </div>
    </div>
  );
};
