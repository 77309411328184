import {
  FolderPlusIcon,
  InformationCircleIcon,
  PaperClipIcon,
} from '@heroicons/react/20/solid';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { InvoicePDF } from './InvoicePDF';
import { InvoicePDF } from './InvoicePDF';
import { InvoiceSend } from './InvoiceSend';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import { Simpacc } from '@components/ButtonSwitchContainer/React-Icon/Simpacc';
import {
  CallToActionContainer,
  FormButtonsContainer,
} from '@components/Container';
import {
  Details,
  MainBoxDetailsColumn,
  DataHeader,
  DetailsButton,
  DetailTableDataLeft,
  DetailTableDataCenter,
  DetailTableDataRight,
  DetailTableHeaderLeft,
  DetailTableHeaderCenter,
  DetailTableHeaderRight,
  MoreAction,
} from '@components/Details';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import { routes } from '@config/routes';
import {
  BillingAddressResultViewModel,
  billingAddressDefaultValue,
} from '@models/BillingAddress';
import { ResultViewModel } from '@models/common/ResultViewModel';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import {
  InvoiceResultViewModel,
  invoiceDefaultValue,
  getStatusText,
} from '@models/Invoice';
import { InvoiceDetailViewModel } from '@models/InvoiceDetail';
import { InvoiceDetailSalesTaxViewModel } from '@models/InvoiceDetailSalesTax';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { InvoiceService } from '@services/Invoice';
import { formatAsCurrency } from '@utils/numberFormat';
import { toTitleCase } from '@utils/titleCase';

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  const dateTimeString = new Date(date).toLocaleString('en-US', options);

  return `${dateTimeString}`;
};

export const InvoiceDetails = () => {
  const [data, setData] = useState(invoiceDefaultValue);
  const { id } = useParams();
  const [customer, setCustomer] = useState(customerDefaultValue);

  const [showCustomerDetails, setCustomerDetails] = useState(false);

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const [showEditCustomers, setShowEditCustomers] = useState(false);

  const [showSend, setShowSend] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceDetailViewModel[]
  >([]);

  const loadInvoiceDetails = () => {
    InvoiceService.getInvoiceDetails(Number(id)).then((result) => {
      setInvoiceDetails(result.data);
    });
  };

  const loadData = () => {
    InvoiceService.getById(Number(id))
      .then((response) => response.data as Promise<InvoiceResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setData(result.data);
          setIsLoading(false);

          if (result.data.billingAddressID) {
            BillingAddressService.getById(result.data.billingAddressID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => setCustomerAddress(addressResult.data));
          }

          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomer(customerResult.data);
              });
          }

          console.log('Success: Fetched the record');
        } else {
          console.log(
            `Error: Failed to get the record. ${result.errorMessage}`
          );
          setIsLoading(true);
        }
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
      loadInvoiceDetails();
    }
  }, []);

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_INVOICES}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  const handleEditCustomer = (rowId: any) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = invoiceDetails.map((o) =>
      o.invoiceDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({
          ...salesTaxes,
          salesTaxKey: `${salesTaxes.salesTaxAbbreviation} (${salesTaxes.rate}%)`,
        }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxKey'
    ) as InvoiceDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const handlePrint = (rowId: number) => {
    console.log(rowId);
    toast.success('Print selected Invoice', {
      position: toast.POSITION.TOP_LEFT,
      theme: 'colored',
      autoClose: 1000,
    });
  };

  const [showPDF, setShowPDF] = useState(false);

  const handleDownloadPDF = (rowId: number) => {
    const message = `Generating PDF`;
    toast.info(`${message}`, {
      position: toast.POSITION.TOP_LEFT,
      theme: 'colored',
      autoClose: 300,
    });
    setTimeout(() => {
      setShowPDF(true);
      console.log(rowId);
    }, 1000);
  };

  const downloadPDF = () => {
    const element = document.getElementById('pdf-content');
    if (element) {
      html2pdf()
        .from(element)
        .set({
          margin: 0.3,
          filename: `Invoice No: ${data.invoiceNumber}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .save()
        .then(() => {
          console.log('PDF successfully downloaded.');
          setShowPDF(false);
        })
        .catch((error: any) => {
          console.error('Error during PDF generation:', error);
        });
    } else {
      console.error('No content found to download as PDF.');
    }
  };

  const duplicateRecord = (rowId: number) => {
    InvoiceService.duplicate(rowId).then((response) => {
      if (response.status == 200) {
        const message = `Successfuly duplicated.`;
        toast.success(`${message}`, {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`${routes.RNC_INVOICES}`);
        }, 1000);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Invoice duplication encountered an error. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const deleteRecord = (rowId: number) => {
    InvoiceService.delete(rowId).then((response) => {
      if (response.status == 204) {
        toast.error('Successfully deleted the record', {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          navigate(`${routes.RNC_INVOICES}`);
        }, 500);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const [showDelete, setDelete] = useState(false);

  const handleDelete = (rowId: number) => {
    setDelete(true);
    console.log(rowId);
  };

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <GridHeaderMenuTitle>
          <div className="text-xl font-bold">{data.invoiceNumber}</div>

          <div className="flex flex-row space-x-2">
            <MoreAction
              dropDownActions={[
                {
                  title: 'Duplicate Record',
                  onClick: () => duplicateRecord(data.id),
                },
                {
                  showTopSeparator: true,
                  title: 'Print',
                  onClick: () => handlePrint(data.id),
                },
                {
                  title: 'Export to PDF',
                  onClick: () => handleDownloadPDF(data.id),
                },
              ]}
              deleteAction={[
                {
                  title: 'Delete',
                  onClick: () => handleDelete(data.id),
                },
              ]}
            />
            <PrimaryButton
              onClick={() => {
                navigate(`${routes.RNC_INVOICES}/create`, {
                  state: { from: location },
                });
              }}
            >
              Create new invoice
            </PrimaryButton>
          </div>
        </GridHeaderMenuTitle>
        <Details>
          <MainBoxDetailsColumn>
            <div className="flex col-span-1 grid-cols-2 space-x-24">
              <div className="col-span-1">
                <DataHeader label="Status" />
                <div className="inline-flex items-center rounded-md px-4 py-1 text-base font-medium ring-1 ring-inset ring-primary-600/20">
                  {getStatusText(data.status)}
                </div>
              </div>
              <div className="col-span-1">
                <DataHeader label="Customer" />
                <div className="text-primary-500 text-lg hover:text-primary-600 font-bold">
                  <button
                    className="flex gap-x-2 items-start uppercase text-start"
                    onClick={() => setCustomerDetails(true)}
                  >
                    <div>{customer.companyName}</div>
                    <InformationCircleIcon width={24} height={24} />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex col-span-1 grid-cols-2 space-x-24">
              <div className="col-span-1">
                <DataHeader label="Invoice Amount" />
                <div className="text-lg font-bold">
                  {formatAsCurrency(
                    invoiceDetails.reduce((acc, cur) => acc + cur.subTotal, 0) +
                      invoiceDetails
                        .map((o) =>
                          o.invoiceDetailSalesTaxes.reduce(
                            (acc, cur) => acc + cur.salesTaxAmount,
                            0
                          )
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <DataHeader label="Valid Until" />
                <div className="text-lg">{formatDate(data.dueDate)}</div>
              </div>
            </div>
          </MainBoxDetailsColumn>

          <div className="">
            <CallToActionContainer>
              <div className="p-6 flex justify-between">
                <div className="space-y-2">
                  <div className="flex text-primary-500 text-lg items-center space-x-4">
                    <FolderPlusIcon width={30} height={30} />
                    <div className="text-gray-400 font-normal text-2xl">
                      Create
                    </div>
                  </div>

                  <div>
                    <span className="font-semibold">Created: </span>
                    {formatDateTime(data.date)}
                  </div>
                </div>

                <div>
                  <DetailsButton onClick={() => handleEdit(data.id)}>
                    Edit Invoice
                  </DetailsButton>
                </div>
              </div>
            </CallToActionContainer>
            <div className="w-2.5 ml-24 bg-gray-200 h-8" />
            <CallToActionContainer>
              <div className="p-6 flex justify-between">
                <div className="space-y-2">
                  <div className="flex text-primary-500 text-lg items-center space-x-4">
                    <PaperClipIcon width={30} height={30} />
                    <div className="text-gray-400 font-normal text-2xl">
                      Send
                    </div>
                  </div>

                  <div>
                    <span className="font-semibold">Last Sent: </span>
                    {data.status}
                  </div>
                </div>

                <div className="space-x-4">
                  <DetailsButton
                    onClick={() => {
                      undefined;
                    }}
                  >
                    Mark as Sent
                  </DetailsButton>

                  <button
                    onClick={() => {
                      setShowSend(true);
                    }}
                    className="rounded-full border-2 text-white border-primary-500 bg-primary-500 px-8 py-1 hover:bg-primary-700 hover:border-primary-700"
                  >
                    Send Invoice
                  </button>
                </div>
              </div>
            </CallToActionContainer>
          </div>

          <div className="shadow-md border">
            <div className="p-8 flex justify-between">
              <div className="flex items-center">
                <Simpacc height="100" width="75" />
              </div>
              <div className="text-xl font-semibold text-end text-gray-500">
                INVOICE
                <div className="text-black text-lg">
                  Tuos Development <br />
                  <span className="text-sm">Canada</span>
                </div>
              </div>
            </div>

            <div className="border-t-2 border-gray-700 py-4 px-8">
              <div className="flex grid-cols-2 justify-between">
                <div className="col-span-1">
                  <div className="text-base font-semibold text-gray-500">
                    Bill to
                  </div>
                  <div className="font-semibold uppercase">
                    {customer.companyName}
                  </div>
                  <div>
                    <span className="uppercase">
                      {customer.primaryContact?.firstname}{' '}
                      {customer.primaryContact?.lastname}
                    </span>
                    <br />
                    {toTitleCase(
                      `${customerAddress.address1} ${customerAddress.address2}`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.city}, ${customerAddress.state} ${customerAddress.postalCode}`
                    )}
                    <br />
                    {toTitleCase(`${customerAddress.country}`)}
                    <br />
                    <br />
                    {customer.primaryContact?.phone}
                    <br />
                    <span className="lowercase">
                      {customer.primaryContact?.email}
                    </span>
                  </div>
                </div>

                <div className="col-span-1 space-y-1">
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 pl-4 pr-1 mr-2">
                    <div>Invoice Number:</div>
                    <div className="font-normal text-end">
                      {data.invoiceNumber}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 pl-4 pr-1 mr-2">
                    <div>Invoice Date:</div>
                    <div className="font-normal text-end">
                      {formatDate(data.date)}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 pl-4 pr-1 mr-2">
                    <div>Valid until:</div>
                    <div className="font-normal text-end">
                      {formatDate(data.dueDate)}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 pl-4 pr-1 mr-2 bg-gray-300 py-2">
                    <div>Grand Total(CAD):</div>
                    <div className="font-normal text-end">
                      {formatAsCurrency(
                        invoiceDetails.reduce(
                          (acc, cur) => acc + cur.subTotal,
                          0
                        ) +
                          invoiceDetails
                            .map((o) =>
                              o.invoiceDetailSalesTaxes.reduce(
                                (acc, cur) => acc + cur.salesTaxAmount,
                                0
                              )
                            )
                            .reduce((acc, cur) => acc + cur, 0)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>

            <div className="">
              <div className="max-w-5x1 mx-auto">
                <TableContainer>
                  <Table>
                    <thead className="bg-primary-100">
                      <tr>
                        <DetailTableHeaderLeft>
                          <span className="pl-2">Item</span>
                        </DetailTableHeaderLeft>

                        <DetailTableHeaderCenter></DetailTableHeaderCenter>
                        <DetailTableHeaderCenter>
                          Quantity
                        </DetailTableHeaderCenter>
                        <DetailTableHeaderCenter>Price</DetailTableHeaderCenter>
                        <DetailTableHeaderRight>
                          <span className="pr-2">Amount</span>
                        </DetailTableHeaderRight>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails.map((row, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? ' bg-white' : 'bg-green-50'
                          }`}
                        >
                          <DetailTableDataLeft>
                            <div className="pl-2 ">
                              <div className="font-semibold text-sm text-start uppercase">
                                <TextWrapper text={row.product?.name || ''} />
                              </div>
                            </div>
                            <div className="font-light text-sm pl-2">
                              <TextWrapper text={row.description || ''} />
                            </div>
                          </DetailTableDataLeft>
                          <DetailTableDataCenter></DetailTableDataCenter>
                          <DetailTableDataCenter>
                            <span className="font-medium text-sm">
                              {row.quantity}
                            </span>
                          </DetailTableDataCenter>
                          <DetailTableDataCenter>
                            <span className="font-medium text-sm">
                              {formatAsCurrency(row.price)}
                            </span>
                          </DetailTableDataCenter>
                          <DetailTableDataRight>
                            <span className="font-medium text-sm">
                              {formatAsCurrency(row.subTotal)}
                            </span>
                          </DetailTableDataRight>
                        </tr>
                      ))}

                      <Space />

                      <tr>
                        <td colSpan={6}>
                          <div className="space-y-1">
                            <div className="flex justify-end mr-6 text-sm">
                              <div className="font-bold mr-4">SubTotal:</div>

                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  invoiceDetails.reduce(
                                    (acc, cur) => acc + cur.subTotal,
                                    0
                                  )
                                )}
                              </div>
                            </div>

                            {Object.entries(getSalesTaxesBySalesTaxID()).map(
                              ([taxTitle, details]) => (
                                <div
                                  className="flex justify-end mr-6 text-sm"
                                  key={taxTitle}
                                >
                                  <div className="font-medium mr-4">
                                    {taxTitle}
                                  </div>

                                  <div className="w-24 text-right">
                                    {formatAsCurrency(
                                      details.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )}

                            <div className="flex justify-end mr-6 pt-2 pl-96 text-sm">
                              <div className="w-full ml-96 flex justify-end border-t border-gray-600 py-2 border-b-4">
                                <div className="font-bold mr-4">Total Tax:</div>
                                <div className="w-24 text-right">
                                  {formatAsCurrency(
                                    invoiceDetails
                                      .map((o) =>
                                        o.invoiceDetailSalesTaxes.reduce(
                                          (acc, cur) =>
                                            acc + cur.salesTaxAmount,
                                          0
                                        )
                                      )
                                      .reduce((acc, cur) => acc + cur, 0)
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end mr-6 text-sm py-4">
                              <div className="font-bold mr-4">Grand Total:</div>
                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  invoiceDetails.reduce(
                                    (acc, cur) => acc + cur.subTotal,
                                    0
                                  ) +
                                    invoiceDetails
                                      .map((o) =>
                                        o.invoiceDetailSalesTaxes.reduce(
                                          (acc, cur) =>
                                            acc + cur.salesTaxAmount,
                                          0
                                        )
                                      )
                                      .reduce((acc, cur) => acc + cur, 0)
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Details>

        {showCustomerDetails && (
          <>
            <Modal show={true}>
              <div className="flex pl-4 justify-between items-center">
                <div className="w-full">Customer Details</div>
                <div>
                  <CloseButton onClick={() => setCustomerDetails(false)} />
                </div>
              </div>
              <Box>
                <div className="px-6 pt-2 pb-8 space-y-6">
                  <div>
                    <div className="font-medium text-medium">
                      {customer.companyName}
                    </div>
                    <div className="text-medium">
                      {customer.primaryContact?.firstname}{' '}
                      {customer.primaryContact?.lastname} <br />
                      {customer.primaryContact?.email}
                    </div>
                    <br />
                    <div className="text-medium">
                      Tel: {customer.primaryContact?.phone}
                    </div>
                  </div>

                  <div className="flex space-x-5">
                    <DetailsButton
                      onClick={() => handleEditCustomer(data.customerID)}
                    >
                      Edit Details
                    </DetailsButton>

                    <DetailsButton
                      onClick={() => {
                        navigate(`${routes.RNC_CUSTOMERS}/${data.customerID}`);
                      }}
                    >
                      View Profile
                    </DetailsButton>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}

        {showEditCustomers && (
          <>
            <Modal show={true}>
              <div className="flex pl-4 justify-between items-center">
                <div className="w-full">Edit Customer Details</div>
                <div>
                  <CloseButton onClick={() => setShowEditCustomers(false)} />
                </div>
              </div>
            </Modal>
          </>
        )}

        {showSend && (
          <>
            <Modal show={true}>
              <InvoiceSend
                onDownload={() => downloadPDF()}
                invoiceId={data.id}
                invoiceName={data.invoiceNumber}
                onClose={() => setShowSend(false)}
              />
            </Modal>
          </>
        )}

        {showDelete && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setDelete(false)} />

              <Box size="medium">
                <div className="px-4">
                  <span>Are you sure to delete this invoice?</span>
                </div>

                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setDelete(false)} />
                    <OkButton
                      label="Delete"
                      onClick={() => deleteRecord(data.id)}
                    />
                  </div>
                </FormButtonsContainer>
              </Box>
            </Modal>
          </>
        )}

        {showPDF && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setShowPDF(false)} />

              <Box size="medium">
                <div className="px-4">
                  <span>Your invoice is now ready for download</span>
                </div>

                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowPDF(false)} />
                    <OkButton label="Download" onClick={() => downloadPDF()} />
                  </div>
                </FormButtonsContainer>
              </Box>
            </Modal>
          </>
        )}

        {/* pdf export */}
        <InvoicePDF invoicesId={data.id} />
        <ToastContainer />
      </>
    );
  }
};
