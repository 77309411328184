import { CollectionSetUp } from '../pages/RevenueAndCollections/CollectionSetUp';
import {
  Contact,
  ContactCreate,
  ContactDetails,
  ContactEdit,
  ContactList,
} from '../pages/RevenueAndCollections/Contacts';
import {
  Currency,
  CurrencyCreate,
  CurrencyDetails,
  CurrencyEdit,
  CurrencyList,
} from '../pages/RevenueAndCollections/Currency';
import {
  Customer,
  CustomerCreate,
  CustomerDetails,
  CustomerEdit,
  CustomerList,
} from '../pages/RevenueAndCollections/Customers';
import { CustomerStatements } from '../pages/RevenueAndCollections/CustomerStatements';
import {
  EstimateAndQuote,
  EstimateAndQuoteCreate,
  EstimateAndQuoteDetails,
  EstimateAndQuoteDetailsModal,
  EstimateAndQuoteEdit,
  EstimateAndQuoteList,
} from '../pages/RevenueAndCollections/EstimateAndQuote';
import { Invoice } from '../pages/RevenueAndCollections/Invoices/Invoice';
import { InvoiceCreate } from '../pages/RevenueAndCollections/Invoices/InvoiceCreate';
import { InvoiceDetails } from '../pages/RevenueAndCollections/Invoices/InvoiceDetails';
import { InvoiceDetailsModal } from '../pages/RevenueAndCollections/Invoices/InvoiceDetailsModal';
import { InvoiceEdit } from '../pages/RevenueAndCollections/Invoices/InvoiceEdit';
import { InvoiceList } from '../pages/RevenueAndCollections/Invoices/InvoiceList';
import { RevenueAndCollections } from '../pages/RevenueAndCollections/RevenueAndCollections';

export const revenueAndCollectionRoutes = {
  path: 'revenue-and-collections',
  element: <RevenueAndCollections />,
  children: [
    {
      path: 'customers',
      element: <Customer />,
      children: [
        { index: true, element: <CustomerList /> },
        { path: 'create', element: <CustomerCreate /> },
        { path: ':id', element: <CustomerDetails /> },
        { path: ':id/edit', element: <CustomerEdit /> },
      ],
    },
    {
      path: 'contacts',
      element: <Contact />,
      children: [
        { index: true, element: <ContactList /> },
        { path: 'create', element: <ContactCreate /> },
        { path: ':id/edit', element: <ContactEdit /> },
        { path: ':id/details', element: <ContactDetails /> },
      ],
    },
    {
      path: 'currency',
      element: <Currency />,
      children: [
        { index: true, element: <CurrencyList /> },
        { path: 'create', element: <CurrencyCreate /> },
        { path: ':id/edit', element: <CurrencyEdit /> },
        { path: ':id/details', element: <CurrencyDetails /> },
      ],
    },
    {
      path: 'invoices',
      element: <Invoice />,
      children: [
        {
          index: true,
          element: <InvoiceList />,
        },
        { path: 'create', element: <InvoiceCreate /> },
        { path: ':id/edit', element: <InvoiceEdit /> },
        { path: ':id', element: <InvoiceDetails /> },
        { path: ':id/pdf', element: <InvoiceDetailsModal /> },
      ],
    },
    { path: 'customer-statements', element: <CustomerStatements /> },
    { path: 'collection-set-up', element: <CollectionSetUp /> },
    {
      path: 'estimates-and-quotes',
      element: <EstimateAndQuote />,
      children: [
        { index: true, element: <EstimateAndQuoteList /> },
        {
          path: 'create',
          element: <EstimateAndQuoteCreate />,
        },
        { path: ':id/edit', element: <EstimateAndQuoteEdit /> },
        { path: ':id', element: <EstimateAndQuoteDetails /> },
        { path: ':id/pdf', element: <EstimateAndQuoteDetailsModal /> },
      ],
    },
  ],
};
