import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface TableAction {
  title: string;
  onClick: () => void;
  showTopSeparator?: boolean;
}

interface TableActionsProps {
  inlineActions?: TableAction[];
  dropdownActions?: TableAction[];
  menuOnTop?: boolean;
}

export const TableActions: React.FC<TableActionsProps> = ({
  inlineActions,
  dropdownActions,
  menuOnTop = false,
}) => {
  return (
    <>
      <div className="flex flex-row">
        {inlineActions?.map((action, index) => (
          <div
            key={index}
            onClick={action.onClick}
            className="hover:bg-primary-500 rounded-md cursor-pointer"
          >
            <span className="w-full text-start px-3">{action.title}</span>
          </div>
        ))}
        <div className="my-auto">
          <Menu as="div" className="absolute ml-auto">
            <Menu.Button className="-my-3 mx-5 block text-primary-500 hover:text-primary-200">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon width="20" height="20" />
            </Menu.Button>
            <Menu.Items
              className={`absolute right-12 -top-1 z-50 mt-0.5 w-32 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none ${
                menuOnTop && '-top-14'
              }`}
            >
              {dropdownActions?.map((action, index) => (
                <>
                  {action.showTopSeparator && (
                    <div
                      key={`${index}separator`}
                      className="border-t-2 border-gray-300 my-2"
                    ></div>
                  )}

                  <Menu.Item key={index}>
                    <div
                      onClick={action.onClick}
                      className="hover:bg-primary-500 rounded-md cursor-pointer"
                    >
                      <span className="w-full text-start px-3">
                        {action.title}
                      </span>
                    </div>
                  </Menu.Item>
                </>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </>
  );
};
