import { Formik } from 'formik';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CustomerEditBillingAddressSelect } from './CustomerEditBillingAddressSelect';
import { CustomerEditContactSelect } from './CustomerEditContactSelect';
import { CustomerEditShippingAddressSelect } from './CustomerEditShippingAddressSelect';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, FormikTextArea, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import {
  CustomerResultViewModel,
  CustomerValidation,
  CustomerViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { CustomerService } from '@services/Customer';
import { routes } from 'src/config/routes';

interface ICustomerEditContextModel {
  customer: CustomerViewModel;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerViewModel>>;
}

export const CustomerEditContext = createContext<ICustomerEditContextModel>({
  customer: customerDefaultValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustomer: () => {},
});

export const CustomerEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_CUSTOMERS;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] =
    useState<CustomerViewModel>(customerDefaultValue);

  const loadData = () => {
    CustomerService.getById(Number(id))
      .then((response) => response.data as Promise<CustomerResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setCustomer(result.data);
          setIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log(`Error: Failed to get record. ${result.errorMessage}`);
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  console.log('CustomerEdit', customer);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <CustomerEditContext.Provider value={{ customer, setCustomer }}>
          <Box>
            <div className="pt-2 pb-6">
              <div className="pt-2 pb-6">
                <p className="w-full text-center text-2xl font-bold text-primary-900">
                  Edit Customer Details
                </p>
              </div>
            </div>

            <Formik
              initialValues={customer}
              validationSchema={CustomerValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                values.primaryContact = undefined;
                values.defaultBillingAddress = undefined;
                values.defaultShippingAddress = undefined;
                values.billingAddresses = undefined;
                values.shippingAddresses = undefined;

                values.contactID = customer.contactID;
                values.billingID = customer.billingID;
                values.shippingID = customer.shippingID;

                CustomerService.update(Number(id), values)
                  .then((response) => {
                    if (response.status == 204) {
                      navigate(previousLocation);
                    } else {
                      console.log('Error: Failed to update a record.');
                    }
                  })
                  .catch((error) => {
                    alert('Error: Failed to handle the request.');
                    console.log(error);
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <FormSectionContainer>
                        <FormikInput
                          label="Name / Company Name"
                          name="companyName"
                        />
                        <FormikInput
                          label="Account Number"
                          name="accountNumber"
                        />
                        <FormikInput label="Website" name="website" />

                        <CustomerEditContactSelect />

                        <CustomerEditBillingAddressSelect />

                        <CustomerEditShippingAddressSelect />

                        <FormikTextArea label="Notes" name="notes" />

                        <Space />
                      </FormSectionContainer>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            navigate(previousLocation);
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Box>
        </CustomerEditContext.Provider>
      </>
    );
  }
};
