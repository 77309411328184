import { Formik } from 'formik';
import { createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomerCreateBillingAddressSelect } from './CustomerCreateBillingAddressSelect';
import { CustomerCreateContactSelect } from './CustomerCreateContactSelect';
import { CustomerCreateShippingAddressSelect } from './CustomerCreateShippingAddressSelect';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, FormikTextArea, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Space } from '@components/Space';
import {
  CustomerValidation,
  CustomerViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { CustomerService } from '@services/Customer';
import { routes } from 'src/config/routes';

interface ICustomerCreateContextModel {
  customer: CustomerViewModel;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerViewModel>>;
}

export const CustomerCreateContext = createContext<ICustomerCreateContextModel>(
  {
    customer: customerDefaultValue,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCustomer: () => {},
  }
);

export const CustomerCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_CUSTOMERS;
  const [customer, setCustomer] =
    useState<CustomerViewModel>(customerDefaultValue);

  return (
    <>
      <CustomerCreateContext.Provider value={{ customer, setCustomer }}>
        <Box>
          <div className="pt-2 pb-6">
            <div className="pt-2 pb-6">
              <p className="w-full text-center text-2xl font-bold text-primary-900">
                Create Customer
              </p>
            </div>
          </div>
          <Formik
            initialValues={customerDefaultValue}
            validationSchema={CustomerValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              values.primaryContact = undefined;
              values.defaultBillingAddress = undefined;
              values.defaultShippingAddress = undefined;

              if (!!customer.contacts && customer.contacts.length > 0) {
                if (
                  customer.contactUiid != undefined &&
                  customer.contactUiid != 0
                ) {
                  const primaryContact = customer.contacts.find(
                    (row) => row.uiid == parseInt(`${customer.contactUiid}`)
                  );

                  values.contactID = primaryContact?.uiid;
                  values.primaryContact = primaryContact;

                  values.contacts = customer.contacts.filter(
                    (o) => o.uiid !== parseInt(`${customer.contactUiid}`)
                  );
                } else {
                  values.contacts = customer.contacts;
                }
              }

              if (
                !!customer.billingAddresses &&
                customer.billingAddresses.length > 0
              ) {
                if (
                  customer.billingUiid != undefined &&
                  customer.billingUiid != 0
                ) {
                  const defaultBillingAddress = customer.billingAddresses.find(
                    (row) => row.uiid == parseInt(`${customer.billingUiid}`)
                  );

                  values.billingID = defaultBillingAddress?.uiid;
                  values.defaultBillingAddress = defaultBillingAddress;

                  values.billingAddresses = customer.billingAddresses.filter(
                    (o) => o.uiid !== parseInt(`${customer.billingUiid}`)
                  );
                } else {
                  values.billingAddresses = customer.billingAddresses;
                }
              }

              if (
                !!customer.shippingAddresses &&
                customer.shippingAddresses.length > 0
              ) {
                if (
                  customer.shippingUiid != undefined &&
                  customer.shippingUiid != 0
                ) {
                  const defaultShippingAddress =
                    customer.shippingAddresses.find(
                      (row) => row.uiid == parseInt(`${customer.shippingUiid}`)
                    );

                  values.shippingID = defaultShippingAddress?.uiid;
                  values.defaultShippingAddress = defaultShippingAddress;

                  values.shippingAddresses = customer.shippingAddresses.filter(
                    (o) => o.uiid !== parseInt(`${customer.shippingUiid}`)
                  );
                } else {
                  values.shippingAddresses = customer.shippingAddresses;
                }
              }

              CustomerService.create(values)
                .then((response) => {
                  if (response.status == 201) {
                    navigate(`${routes.RNC_CUSTOMERS}`);
                  } else {
                    console.log('Error: Failed to create a record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput
                        label="Name / Company Name"
                        name="companyName"
                      />
                      <FormikInput
                        label="Account Number"
                        name="accountNumber"
                      />
                      <FormikInput label="Website" name="website" />

                      <CustomerCreateContactSelect />

                      <CustomerCreateBillingAddressSelect />

                      <CustomerCreateShippingAddressSelect />

                      <FormikTextArea label="Notes" name="notes" />

                      <Space />
                    </FormSectionContainer>

                    <FormButtonsContainer>
                      <CancelButton
                        onClick={() => {
                          navigate(previousLocation);
                        }}
                      />

                      <SubmitButton
                        label="Save"
                        disabled={
                          formikProps.isSubmitting || !formikProps.isValid
                        }
                      />
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </CustomerCreateContext.Provider>
    </>
  );
};
