import * as Yup from 'yup';

import {
  billingAddressDefaultValue,
  BillingAddressViewModel,
} from './BillingAddress';
import { ResultViewModel } from './common/ResultViewModel';
import { SelectOption } from './common/SelectOption';
import { TableViewModel } from './common/TableViewModel';
import { contactDefaultValue, ContactViewModel } from './Contact';
import {
  shippingAddressDefaultValue,
  ShippingAddressViewModel,
} from './ShippingAddress';

export interface CustomerViewModel {
  id: number;
  uiid: number;
  companyName: string;
  accountNumber?: string;
  website?: string;
  notes?: string;
  contactID?: number;
  contactUiid?: number;
  primaryContact?: ContactViewModel;
  contacts?: ContactViewModel[];
  billingID?: number;
  billingUiid?: number;
  defaultBillingAddress?: BillingAddressViewModel;
  billingAddresses?: BillingAddressViewModel[];
  shippingID?: number;
  shippingUiid?: number;
  defaultShippingAddress?: ShippingAddressViewModel;
  shippingAddresses?: ShippingAddressViewModel[];
  IsActive?: boolean;
}

export interface CustomerTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: CustomerViewModel[];
}

export interface CustomerResultViewModel extends ResultViewModel {
  data: CustomerViewModel;
}

export interface CustomerContactSelectOptionResultViewModel
  extends ResultViewModel {
  data: SelectOption[];
}

export interface BillingAddressesSelectOptionResultViewModel
  extends ResultViewModel {
  data: SelectOption[];
}

export interface ShippingAddressesSelectOptionResultViewModel
  extends ResultViewModel {
  data: SelectOption[];
}
export interface CustomerSelectOptionResultViewModel extends ResultViewModel {
  data: SelectOption[];
}

export const customerDefaultValue: CustomerViewModel = {
  id: 0,
  uiid: 0,
  companyName: '',
  notes: '',
  accountNumber: '',
  website: '',
  contactID: 0,
  contactUiid: 0,
  primaryContact: contactDefaultValue,
  contacts: [],
  billingID: 0,
  billingUiid: 0,
  defaultBillingAddress: billingAddressDefaultValue,
  billingAddresses: [],
  shippingID: 0,
  shippingUiid: 0,
  defaultShippingAddress: shippingAddressDefaultValue,
  shippingAddresses: [],
  IsActive: true,
};

export const CustomerValidation = Yup.object().shape({
  companyName: Yup.string()
    .max(50, 'Company Name exceeds the maximum length of 50 characters')
    .required('Company Name is Required'),
  accountNumber: Yup.string()
    .max(50, 'Account Number exceeds the maximum length of 50 characters')
    .nullable(),
  website: Yup.string()
    .max(100, 'Website exceeds the maximum length of 100 characters')
    .nullable(),
  notes: Yup.string()
    .max(200, 'Notes exceeds the maximum length of 200 characters')
    .nullable(),
});
