import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';

import { CustomerEditContext } from './CustomerEdit';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import {
  BillingAddressViewModel,
  billingAddressDefaultValue,
  billingAddressValidation,
} from '@models/BillingAddress';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';

export const CustomerEditBillingAddressSelect = () => {
  const { customer, setCustomer } = useContext(CustomerEditContext);
  const [
    isCustomerBillingAddressesLoading,
    setIsCustomerBillingAddressesLoading,
  ] = useState(true);
  const customerBillingAddresses = customer.billingAddresses || [];
  const [
    customerBillingAddressSelectOptions,
    setCustomerBillingAddressSelectOptions,
  ] = useState<FormikSelectOption[]>([]);
  const [isShowAddBillingAddress, setIsShowAddBillingAddress] = useState(false);
  const [isShowEditBillingAddress, setIsShowEditBillingAddress] =
    useState(false);
  const [billingAddressToEdit, setBillingAddressToEdit] =
    useState<BillingAddressViewModel | null>();
  const [isLoading, setIsLoading] = useState(true);

  const loadBillingAddresses = () => {
    CustomerService.getCustomerBillingAddresses(Number(customer.id)).then(
      (result) => {
        setCustomer((prevState) => ({
          ...prevState,
          billingAddresses: result.data,
        }));

        setIsCustomerBillingAddressesLoading(false);
      }
    );
  };

  const loadBillingAddressOptions = () => {
    const customerBillingAddressSelection = [{ value: '0', text: '' }].concat(
      customerBillingAddresses.map((row) => ({
        value: `${row.id}`,
        text: `${row.address1} ${row.address2}, ${row.city}, ${row.state} ${row.postalCode}, ${row.country}`,
      }))
    );

    setCustomerBillingAddressSelectOptions(customerBillingAddressSelection);
  };

  useEffect(() => {
    loadBillingAddressOptions();
  }, [customer.billingAddresses]);

  useEffect(() => {
    if (!isCustomerBillingAddressesLoading) {
      setIsLoading(false);
    }
  }, [isCustomerBillingAddressesLoading]);

  useEffect(() => {
    loadBillingAddresses();
  }, [customer.id]);

  console.log('CustomerEditBillingAddressSelect', customer);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <ActionableSelect
          label="Billing Address"
          value={`${customer.billingID}`}
          selection={customerBillingAddressSelectOptions}
          onAddAction={() => {
            setIsShowAddBillingAddress(true);
          }}
          onEditAction={() => {
            if (customer.billingID == undefined || customer.billingID == 0)
              return;

            const selectedBillingAddress = customerBillingAddresses.find(
              (row) => row.id == customer.billingID
            );

            if (selectedBillingAddress == undefined) return;

            setBillingAddressToEdit(selectedBillingAddress);
            setIsShowEditBillingAddress(true);
          }}
          onDeleteAction={() => {
            const deleteBillingAddress = customerBillingAddresses.find(
              (o) => o.id === customer.billingID
            );

            if (deleteBillingAddress == undefined) return;

            BillingAddressService.delete(deleteBillingAddress.id).then(
              (response) => {
                if (response.status === 204) {
                  const updatedBillingAddresses =
                    customerBillingAddresses.filter(
                      (o) => o.id !== customer.billingID
                    );
                  setCustomer({
                    ...customer,
                    billingID: 0,
                    billingAddresses: updatedBillingAddresses,
                  });
                }
              }
            );
          }}
          onDuplicateAction={() => {
            const selectedBillingAddress = customerBillingAddresses.find(
              (row) => row.id == customer.billingID
            );

            if (selectedBillingAddress == undefined) return;

            selectedBillingAddress.id = 0;
            BillingAddressService.create(selectedBillingAddress).then(
              (response) => {
                if (response.status === 201) {
                  const newBillingAddress = {
                    ...selectedBillingAddress,
                    id: response.data.data.id,
                  };

                  const updatedBillingAddresses =
                    customerBillingAddresses.concat(newBillingAddress);

                  setCustomer({
                    ...customer,
                    billingAddresses: updatedBillingAddresses,
                  });

                  setBillingAddressToEdit(newBillingAddress);
                  setIsShowEditBillingAddress(true);
                }
              }
            );
          }}
          onChange={(e) => {
            const updateCustomer = {
              ...customer,
              billingID: Number(e.target.value),
            };
            setCustomer(updateCustomer);
          }}
        />

        {isShowAddBillingAddress && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setIsShowAddBillingAddress(false)} />

              <Formik
                initialValues={billingAddressDefaultValue}
                validationSchema={billingAddressValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  values.customerId = Number(customer.id);

                  BillingAddressService.create(values).then((response) => {
                    if (response.status === 201) {
                      values.id = response.data.data.id;
                      const updatedBillingAddresses =
                        customerBillingAddresses.concat(values);

                      setCustomer({
                        ...customer,
                        billingID: values.id,
                        billingAddresses: updatedBillingAddresses,
                      });

                      actions.resetForm();
                      setIsShowAddBillingAddress(false);
                    }
                  });
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput
                            label="Currency Code"
                            name="currencyCode"
                          />
                          <FormikInput label="Address 1" name="address1" />
                          <FormikInput label="Address 2" name="address2" />
                          <FormikInput label="City" name="city" />
                          <FormikInput label="State / Province" name="state" />
                          <FormikInput label="Country" name="country" />
                          <FormikInput label="Postal Code" name="postalCode" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowAddBillingAddress(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}

        {isShowEditBillingAddress && billingAddressToEdit && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setIsShowEditBillingAddress(false)} />

              <Formik
                initialValues={billingAddressToEdit}
                validationSchema={billingAddressValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  BillingAddressService.update(values.id, values).then(
                    (response) => {
                      if (response.status === 204) {
                        const updatedBillingAddresses =
                          customerBillingAddresses.map((row) =>
                            row.id === values.id ? values : row
                          );
                        setCustomer({
                          ...customer,
                          billingID: values.id,
                          billingAddresses: updatedBillingAddresses,
                        });

                        actions.resetForm();
                        setIsShowEditBillingAddress(false);
                      }
                    }
                  );
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput
                            label="Currency Code"
                            name="currencyCode"
                          />
                          <FormikInput label="Address 1" name="address1" />
                          <FormikInput label="Address 2" name="address2" />
                          <FormikInput label="City" name="city" />
                          <FormikInput label="State / Province" name="state" />
                          <FormikInput label="Country" name="country" />
                          <FormikInput label="Postal Code" name="postalCode" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowEditBillingAddress(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </>
    );
  }
};
