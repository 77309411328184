import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import MyAreaChart from '@components/Charts';
import { PageContainer } from '@components/PageContainer';
import CustomPieChart from '@components/PieChart';
import MyBarChart from '@components/TableContainers/Bar';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export const DashBoard = () => {
  const sampleData = [
    { name: '2012', uv: 4000, pv: 2400 },
    { name: '2013', uv: 3000, pv: 1398 },
    { name: '2014', uv: 2000, pv: 9800 },
    { name: '2015', uv: 2780, pv: 3908 },
    { name: '2016', uv: 1890, pv: 4800 },
    { name: '2017', uv: 2390, pv: 3800 },
    { name: '2018', uv: 3490, pv: 4300 },
  ];
  return (
    <>
      <PageContainer>
        <BreadCrumbs links={[{ link: 'Dashboard', to: `/` }]} />

        <HeaderContainer>
          <div className="text-sm h-full w-full flex justify-center">
            <div className="  ">
              <ul className="flex items-center">
                <li className="  p-2 ">
                  <div className=" w-[269px]  positive">
                    <div className="h-[41px]  bg-[#53CC96]  flex">
                      <div className="h-full w-[50%] flex">
                        <p className="text-white flex items-center pl-[10px] ">
                          Lorem Ipsum
                        </p>
                      </div>

                      <div className="h-full w-[50%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          className="bi bi-cart-check-fill -white float-right stroke-white m-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />{' '}
                        </svg>
                      </div>
                    </div>

                    <div className="h-[93px]  bg-white text-xs flex items-center justify-center">
                      <div className="flex  border-b border-b-[#D9D9D9]   ">
                        <button className="h-[15px] bg-blue-500 text-white border rounded-md flex items-start text-[10px] ">
                          + 100%
                        </button>
                        <p className="text-[10px] pb-2 pl-2">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 bg-white ">
                      <div className="flex justify-start pl-4 text-[10px] semi-bold">
                        <p>12345</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width="14"
                          height="14"
                          fill="blue"
                          className="ml-2  mt-[3px]"
                        >
                          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                        </svg>
                      </div>
                      <div className="flex justify-end pr-4 text-[10px] font-light">
                        <p>Last: 12345</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="  p-2 ">
                  <div className=" w-[269px]  positive">
                    <div className="h-[41px]  bg-[#53CC96]  flex">
                      <div className="h-full w-[50%] flex">
                        <p className="text-white flex items-center pl-[10px] ">
                          Lorem Ipsum
                        </p>
                      </div>

                      <div className="h-full w-[50%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          className="bi bi-cart-check-fill -white float-right stroke-white m-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />{' '}
                        </svg>
                      </div>
                    </div>

                    <div className="h-[93px]  bg-white text-xs flex items-center justify-center">
                      <div className="flex  border-b border-b-[#D9D9D9]   ">
                        <button className="h-[15px] bg-red-500 text-white border rounded-md flex items-start text-[10px] ">
                          + 100%
                        </button>
                        <p className="text-[10px] pb-2 pl-2">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 bg-white">
                      <div className="flex justify-start pl-4 text-[10px] semi-bold">
                        <p>12345</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width="14"
                          height="14"
                          fill="blue"
                          className="ml-2  mt-[3px]"
                        >
                          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                        </svg>
                      </div>
                      <div className="flex justify-end pr-4 text-[10px] font-light">
                        <p>Last: 12345</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="  p-2 ">
                  <div className=" w-[269px]  positive">
                    <div className="h-[41px]  bg-[#53CC96]  flex">
                      <div className="h-full w-[50%] flex">
                        <p className="text-white flex items-center pl-[10px] ">
                          Lorem Ipsum
                        </p>
                      </div>

                      <div className="h-full w-[50%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          className="bi bi-cart-check-fill -white float-right stroke-white m-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />{' '}
                        </svg>
                      </div>
                    </div>

                    <div className="h-[93px]  bg-white text-xs flex items-center justify-center">
                      <div className="flex  border-b border-b-[#D9D9D9]   ">
                        <button className="h-[15px] bg-blue-500 text-white border rounded-md flex items-start text-[10px] ">
                          + 100%
                        </button>
                        <p className="text-[10px] pb-2 pl-2">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 bg-white">
                      <div className="flex justify-start pl-4 text-[10px] semi-bold">
                        <p>12345</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width="14"
                          height="14"
                          fill="red"
                          className="ml-2  mt-[3px]"
                        >
                          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                        </svg>
                      </div>
                      <div className="flex justify-end pr-4 text-[10px] font-light">
                        <p>Last: 12345</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="  p-2 ">
                  <div className=" w-[269px]  positive">
                    <div className="h-[41px]  bg-[#53CC96]  flex">
                      <div className="h-full w-[50%] flex">
                        <p className="text-white flex items-center pl-[10px] ">
                          Lorem Ipsum
                        </p>
                      </div>

                      <div className="h-full w-[50%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          className="bi bi-cart-check-fill -white float-right stroke-white m-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />{' '}
                        </svg>
                      </div>
                    </div>

                    <div className="h-[93px]  bg-white text-xs flex items-center justify-center">
                      <div className="flex  border-b border-b-[#D9D9D9]   ">
                        <button className="h-[15px] bg-green-500 text-white border rounded-md flex items-start text-[10px]">
                          + 100%
                        </button>
                        <p className="text-[10px] pb-2 pl-2">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 bg-white">
                      <div className="flex justify-start pl-4 text-[10px] semi-bold">
                        <p>12345</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width="14"
                          height="14"
                          fill="blue"
                          className="ml-2  mt-[3px]"
                        >
                          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                        </svg>
                      </div>
                      <div className="flex justify-end pr-4 text-[10px] font-light">
                        <p>Last: 12345</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </HeaderContainer>

        <div className="flex justify-center">
          <div className="w-[678px] ">
            <MyAreaChart data={sampleData} />
          </div>
          <div className="w-[440px] ml-[1rem]">
            <MyBarChart data={sampleData} />
          </div>
        </div>
        <div className="flex  justify-center  pt-4">
          <div className="bg-white w-[330px] h-[185px]">
            <div>
              <p className="text-black text-[8px] p-2 font-semibold font-inter">
                Monthly Earning
              </p>
            </div>
            <div className="flex">
              <div className="w-[180px] p-[5px]">
                <p className="text-xl font-bold ">$6451</p>
                <p className="text-[12px] p-[5px] font-semibold">
                  Monthly Earning
                </p>
                <p className="text-[10px] p-2 ">Lorem ipsum dolor </p>
                <p className="text-green-300 text-sm font-semibold">
                  Learn more...
                </p>
              </div>
              <div className="w-[60px] p-[5px] positive ">
                <div className="ml-4 w-full float-right">
                  <CustomPieChart />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-[330px] ml-[1.1rem] h-[185px]">
            <div>
              <p className="text-black text-[10px] p-4 font-bold font-inter">
                Recent Activity Feed
              </p>
            </div>
            <div className="flex">
              <div className="w-[50%]">
                <p className="p-2 ml-4 text-xs">12 Oct</p>
              </div>
              <div className="w-[50%]">
                <p className="text-[10px]">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p className="text-[10px]">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-[50%]">
                <p className="p-2 ml-4 text-xs">12 Oct</p>
              </div>
              <div className="w-[50%]">
                <p className="text-[10px]">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p className="text-[10px]">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white w-[440px] flex ml-[1.1rem] h-[185px]">
            <div className="w-[210px] p-[5x] h-full mr-[20px] bg-[#59CB9A]">
              <div className="h-20 w-full flex items-center justify-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="24"
                    height="24"
                    fill="white"
                  >
                    <path d="M416 128c-.6 0-1.1 .2-1.6 .2 1.1-5.2 1.6-10.6 1.6-16.2 0-44.2-35.8-80-80-80-24.6 0-46.3 11.3-61 28.8C256.4 24.8 219.3 0 176 0 114.1 0 64 50.1 64 112c0 7.3 .8 14.3 2.1 21.2C27.8 145.8 0 181.5 0 224c0 53 43 96 96 96h320c53 0 96-43 96-96s-43-96-96-96zM88 374.2c-12.8 44.4-40 56.4-40 87.7 0 27.7 21.5 50.1 48 50.1s48-22.4 48-50.1c0-31.4-27.2-43.1-40-87.7-2.2-8.1-13.5-8.5-16 0zm160 0c-12.8 44.4-40 56.4-40 87.7 0 27.7 21.5 50.1 48 50.1s48-22.4 48-50.1c0-31.4-27.2-43.1-40-87.7-2.2-8.1-13.5-8.5-16 0zm160 0c-12.8 44.4-40 56.4-40 87.7 0 27.7 21.5 50.1 48 50.1s48-22.4 48-50.1c0-31.4-27.2-43.1-40-87.7-2.2-8.1-13.5-8.5-16 0z" />
                  </svg>
                </span>
              </div>
              <div>
                <p className="text-white w-full text-xs flex items-center justify-center">
                  32* C
                </p>
              </div>
              <div>
                <p className="text-white text-xs w-full flex items-center justify-center">
                  Heavy rain
                </p>
              </div>
              <div>
                <p className=" h-20 text-white w-full flex items-center justify-center">
                  New York
                </p>
              </div>
            </div>
            <div className="w-[210px] p-[5x] h-full bg-[#F9C053]">
              <div className="h-20 w-full flex items-center justify-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    width="24"
                    height="24"
                    fill="white"
                  >
                    <path d="M575.2 325.7c.2-1.9 .8-3.7 .8-5.6 0-35.3-28.7-64-64-64-12.6 0-24.2 3.8-34.1 10-17.6-38.8-56.5-66-101.9-66-61.8 0-112 50.1-112 112 0 3 .7 5.8 .9 8.7-49.6 3.7-88.9 44.7-88.9 95.3 0 53 43 96 96 96h272c53 0 96-43 96-96 0-42.1-27.2-77.4-64.8-90.4zm-430.4-22.6c-43.7-43.7-43.7-114.7 0-158.3 43.7-43.7 114.7-43.7 158.4 0 9.7 9.7 16.9 20.9 22.3 32.7 9.8-3.7 20.1-6 30.7-7.5L386 81.1c4-11.9-7.3-23.1-19.2-19.2L279 91.2 237.5 8.4C232-2.8 216-2.8 210.4 8.4L169 91.2 81.1 61.9C69.3 58 58 69.3 61.9 81.1l29.3 87.8-82.8 41.5c-11.2 5.6-11.2 21.5 0 27.1l82.8 41.4-29.3 87.8c-4 11.9 7.3 23.1 19.2 19.2l76.1-25.3c6.1-12.4 14-23.7 23.6-33.5-13.1-5.4-25.4-13.4-36-24zm-4.8-79.2c0 40.8 29.3 74.8 67.9 82.3 8-4.7 16.3-8.8 25.2-11.7 5.4-44.3 31-82.5 67.4-105C287.3 160.4 258 140 224 140c-46.3 0-84 37.6-84 83.9z" />
                  </svg>
                </span>
              </div>
              <div>
                <p className="text-white w-full text-xs flex items-center justify-center">
                  32* C
                </p>
              </div>
              <div>
                <p className="text-white text-xs w-full flex items-center justify-center">
                  Partly Cloudy
                </p>
              </div>
              <div>
                <p className=" h-20 text-white w-full flex items-center justify-center">
                  California
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
