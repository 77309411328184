import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { DropDownContainer } from './DropDownContainer';
import { DropDownItems } from './DropDownItems';
import { DropDownMenuContainer } from './DropDownMenuContainer';
import { DropDownMenuItems } from './DropDownMenuItems';
import { HandleClicker } from './HandleClicker';
import { SideBarContainer } from './SideBarContainer';
import { SideBarMenuContainer } from './SideBarMenuContainer';

import { AccountingIcons } from '@components/ButtonSwitchContainer/React-Icon/AccountingIcons';
import { DashBoardIcons } from '@components/ButtonSwitchContainer/React-Icon/DashBoard';
import { DropDownIcon } from '@components/ButtonSwitchContainer/React-Icon/DropDownIcon';
import { InventoryIcon } from '@components/ButtonSwitchContainer/React-Icon/InventoryIcon';
import { PurchaseIcons } from '@components/ButtonSwitchContainer/React-Icon/PurchaseIcons';
import { ReportsIcons } from '@components/ButtonSwitchContainer/React-Icon/ReportIcons';
import { RevenueIcons } from '@components/ButtonSwitchContainer/React-Icon/RevenueIcons';
import { SettingsIcon } from '@components/ButtonSwitchContainer/React-Icon/SettingsIcon';
import { Simpacc } from '@components/ButtonSwitchContainer/React-Icon/Simpacc';
import { routes } from '@config/routes';

export const MobileSideBar = () => {
  const [activeMenu, setActiveMenu] = useState(
    localStorage.getItem('activeMenu') || null
  );
  const [activeSubMenu, setActiveSubMenu] = useState<string | boolean>(false);
  const [activeSubMenuMenu, setActiveSubMenuMenu] = useState<string | boolean>(
    false
  );
  const [showInventory, setShowInventory] = useState(false);
  const [showRevenue, setRevenue] = useState(false);
  const [showPurchase, setPurchase] = useState(false);
  const [showAccounting, setAccounting] = useState(false);
  const [showReport, setReport] = useState(false);
  const [showSettings, setSettings] = useState(false);
  const [showProfit, setProfit] = useState(false);

  const [activeReportSub, setActiveReportSub] = useState(false);

  const location = useLocation();

  const { id } = useParams();

  const setActiveSubMenuByRoute = (route: any) => {
    if (route === routes.HOME) {
      setActiveMenu('dashboard');
      setActiveSubMenu(false);
    } else if (
      route === routes.RNC_ESTIMATES ||
      route === `${routes.RNC_ESTIMATES}/create` ||
      route === `${routes.RNC_ESTIMATES}/${id}` ||
      route === `${routes.RNC_ESTIMATES}/${id}/edit`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('estimate');
    } else if (
      route === routes.RNC_INVOICES ||
      route === `${routes.RNC_INVOICES}/create` ||
      route === `${routes.RNC_INVOICES}/${id}` ||
      route === `${routes.RNC_INVOICES}/${id}/edit`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('invoice');
    } else if (route === routes.COLLECTION_SETUP) {
      setActiveMenu('revenue');
      setActiveSubMenu('collectionSetup');
    } else if (route === routes.CUSTOMER_STATEMENT) {
      setActiveMenu('revenue');
      setActiveSubMenu('customerStatements');
    } else if (
      route === routes.RNC_CUSTOMERS ||
      route === `${routes.RNC_CUSTOMERS}/create` ||
      route === `${routes.RNC_CUSTOMERS}/${id}` ||
      route === `${routes.RNC_CUSTOMERS}/${id}/edit`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('customer');
    } else if (route === routes.CONTACTS) {
      setActiveMenu('revenue');
      setActiveSubMenu('contacts');
    } else if (route === routes.CURRENCY) {
      setActiveMenu('revenue');
      setActiveSubMenu('currency');
    } else if (route === routes.CATEGORIES) {
      setActiveMenu('inventories');
      setActiveSubMenu('categories');
    } else if (route === routes.INVENTORY_PRODUCTS) {
      setActiveMenu('inventories');
      setActiveSubMenu('products');
    } else if (route === routes.BILLS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('bills');
    } else if (route === routes.PAYMENTS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('paymentSetup');
    } else if (route === routes.VENODRS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('vendors');
    } else if (route === routes.PURCHASE_PRODUCTS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('productandServices');
    } else if (route === routes.TRANSACTIONS) {
      setActiveMenu('accounting');
      setActiveSubMenu('transaction');
    } else if (route === routes.RECON) {
      setActiveMenu('accounting');
      setActiveSubMenu('recon');
    } else if (route === routes.CHARTOFACCOUNTS) {
      setActiveMenu('accounting');
      setActiveSubMenu('charts');
    } else if (route === routes.BANK_CONNECTIONS) {
      setActiveMenu('accounting');
      setActiveSubMenu('bankConnection');
    } else if (route === routes.COMPARATIVE) {
      setActiveMenu('reports');
      setActiveSubMenuMenu('comparative');
      setActiveSubMenu(false);
    } else if (route === routes.QUARTER) {
      setActiveMenu('reports');
      setActiveSubMenuMenu('quarter');
    } else if (route === routes.YEAR_TO_DATE) {
      setActiveMenu('reports');
      setActiveSubMenuMenu('yearToyear');
    } else if (route === routes.MONTHLY) {
      setActiveMenu('reports');
      setActiveSubMenuMenu('monthly');
    } else if (route === routes.SALES_TAX) {
      setActiveMenu('settings');
      setActiveSubMenu('sales tax');
    } else {
    }
  };

  useEffect(() => {
    setActiveMenu(localStorage.getItem('activeMenu') || null);

    if (activeMenu !== localStorage.getItem('activeMenu')) {
      setActiveSubMenu(localStorage.getItem('activeSubMenu') || false);
    }

    setActiveSubMenuByRoute(location.pathname);
  }, [location.pathname]);

  const handleDropDownClick = (menu: any) => {
    if (menu === 'revenue') {
      setRevenue((prev) => {
        const newValue = !prev;
        localStorage.setItem('showRevenue', String(newValue));
        return newValue;
      });
    } else if (menu === 'inventories') {
      setShowInventory((prev) => {
        const newValue = !prev;
        localStorage.setItem('showInventory', String(newValue));
        return newValue;
      });
    } else if (menu === 'purchase') {
      setPurchase((prev) => {
        const newValue = !prev;
        localStorage.setItem('showPurchase', String(newValue));
        return newValue;
      });
    } else if (menu === 'accounting') {
      setAccounting((prev) => {
        const newValue = !prev;
        localStorage.setItem('showAccounting', String(newValue));
        return newValue;
      });
    } else if (menu === 'reports') {
      setReport((prev) => {
        const newValue = !prev;
        localStorage.setItem('showReport', String(newValue));

        setProfit((prevProfit) => {
          const newProfitValue = !prevProfit;
          localStorage.setItem('showProfit', String(newProfitValue));
          return newProfitValue;
        });

        return newValue;
      });
    } else if (menu === 'settings') {
      setSettings((prev) => {
        const newValue = !prev;
        localStorage.setItem('showSettings', String(newValue));
        return newValue;
      });
    }
  };

  const handleMenuClick = (menu: any) => {
    setActiveMenu(menu);
    setActiveReportSub(menu);
    setActiveSubMenuMenu(false);
    localStorage.setItem('activeMenu', menu);
  };

  const handleReportMenu = (menu: any) => {
    setActiveMenu(menu);
    setActiveSubMenu(false);
    setActiveReportSub(menu);
    setProfit(true);
    localStorage.setItem('activeMenu', menu);
  };

  const handleSubMenuClick = (menu: any, mainMenu: any) => {
    setActiveSubMenu(menu);
    setActiveSubMenuMenu(false);
    setActiveReportSub(false);
    setActiveMenu(mainMenu);
  };

  const handleProfit = (menu: any) => {
    setActiveSubMenuMenu(menu);
    activeMenuClass(true);
    setActiveSubMenu(false);
    setActiveMenu('reports');
    localStorage.setItem('activeSubMenuMenu', menu);
  };

  const handleReportSub = (menu: any, mainMenu?: any) => {
    setProfit(!showProfit);
    reportDropDownMenuClass(true);
    setActiveSubMenuMenu(menu);
    activeMenuClass(true);
    setActiveMenu('reports');
    setActiveReportSub(mainMenu);
    localStorage.setItem('showProfit', menu);
  };

  const reportDropDownMenuClass = (menu: any) =>
    activeReportSub === menu
      ? 'shadow-md text-black fill-[#F9FEF3] px-4 py-2 font-bold lg:text-xs md:text-xs text-[11px]  w-full space-y-3 rounded-md'
      : 'hover:shadow-md text-[#2B461B] fill-[#27401A] px-4 py-2 hover:bg-[#EEFFE7] w-full font-bold lg:text-xs md:text-xs text-[11px] space-y-3 rounded-md';

  const activeSubMenuClass = (menu: any) =>
    activeSubMenu === menu
      ? 'flex justify py-1  lg:text-xs md:text-xs text-[11px] text-white pl-8 pr-4 w-full space-x-2 rounded-xl bg-primary-500'
      : 'flex justify pl-8 pr-4 py-1  w-full lg:text-xs md:text-xs text-[11px] text-primary-500 hover:text-black space-x-2 rounded-xl hover:bg-[#EEFFE7]';

  const activeSubMenuMenuClass = (menu: any) =>
    activeSubMenuMenu === menu
      ? 'flex justify py-2 font-bold lg:text-xs md:text-xs text-[11px] text-black px-4 w-full space-x-2 rounded-xl bg-primary-500'
      : 'flex justify px-4 py-2 lg:text-xs md:text-xs text-[11px] hover:font-bold w-full text-primary-500 hover:text-black rounded-xl space-x-2 hover:bg-[#EEFFE7]';

  const activeMenuClass = (menu: any) =>
    activeMenu === menu
      ? 'shadow-md text-black  fill-[#F9FEF3] py-0.5 font-bold lg:text-xs md:text-xs text-[11px] pl-8 pr-4 w-full space-y-3 rounded-xl '
      : 'hover:shadow-md text-[#2B461B] fill-[#27401A] pr-4 pl-8 py-0.5 hover:bg-[#EEFFE7] w-full font-md lg:text-xs md:text-xs text-[11px] space-y-3 rounded-xl';

  return (
    <>
      <SideBarContainer>
        <div className="flex justify-center items-center space-x-3 py-6">
          <Simpacc height="75" width="50" />
          <h1 className="font-md text-lg text-primary-500">TUOS</h1>
        </div>

        <SideBarMenuContainer>
          <HandleClicker
            onClick={() => handleMenuClick('dashboard')}
            to={`${routes.HOME}`}
          >
            <div className={`${activeMenuClass('dashboard')}`}>
              <div className="flex space-x-4">
                <DashBoardIcons height="51" width="37" />
                <h1 className="flex items-center">Dashboard</h1>
              </div>
            </div>
          </HandleClicker>
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('revenue')}>
            <div className={`${activeMenuClass('revenue')}`}>
              <div
                className="flex justify-between items-center space-x-4"
                onClick={() => handleDropDownClick('revenue')}
              >
                <div className="flex space-x-4">
                  <RevenueIcons height="51" width="37" />
                  <h1 className="flex items-center">Revenue and Collections</h1>
                </div>
                <div className="">
                  <DropDownIcon height="12" width="8" />
                </div>
              </div>
            </div>
          </div>
          {showRevenue && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.RNC_ESTIMATES}`}
                onClick={() => handleSubMenuClick('estimate', 'revenue')}
                className={`${activeSubMenuClass('estimate')}`}
              >
                Estimates and Quotes
              </DropDownItems>

              <DropDownItems
                to={`${routes.RNC_INVOICES}`}
                onClick={() => handleSubMenuClick('invoice', 'revenue')}
                className={`${activeSubMenuClass('invoice')}`}
              >
                Invoice
              </DropDownItems>

              <DropDownItems
                to={`${routes.COLLECTION_SETUP}`}
                onClick={() => handleSubMenuClick('collectionSetup', 'revenue')}
                className={`${activeSubMenuClass('collectionSetup')}`}
              >
                Collection Set-up
              </DropDownItems>

              <DropDownItems
                to={`${routes.CUSTOMER_STATEMENT}`}
                onClick={() =>
                  handleSubMenuClick('customerStatements', 'revenue')
                }
                className={`${activeSubMenuClass('customerStatements')}`}
              >
                Customer Statements
              </DropDownItems>

              <DropDownItems
                to={`${routes.RNC_CUSTOMERS}`}
                onClick={() => handleSubMenuClick('customer', 'revenue')}
                className={`${activeSubMenuClass('customer')}`}
              >
                Customers
              </DropDownItems>

              <DropDownItems
                to={`${routes.CONTACTS}`}
                onClick={() => handleSubMenuClick('contacts', 'revenue')}
                className={`${activeSubMenuClass('contacts')}`}
              >
                Contacts
              </DropDownItems>

              <DropDownItems
                to={`${routes.CURRENCY}`}
                onClick={() => handleSubMenuClick('currency', 'revenue')}
                className={`${activeSubMenuClass('currency')}`}
              >
                Currency
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div
            className="w-full"
            onClick={() => handleMenuClick('inventories')}
          >
            <div className={`${activeMenuClass('inventories')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('inventories')}
              >
                <div className="flex space-x-4">
                  <InventoryIcon height="51" width="37" />
                  <h1 className="flex items-center">Inventory</h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showInventory && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.CATEGORIES}`}
                onClick={() => handleSubMenuClick('categories', 'inventories')}
                className={`${activeSubMenuClass('categories')}`}
              >
                Category
              </DropDownItems>

              <DropDownItems
                to={`${routes.INVENTORY_PRODUCTS}`}
                onClick={() => handleSubMenuClick('products', 'inventories')}
                className={`${activeSubMenuClass('products')}`}
              >
                Products and Services
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div
            className="w-full"
            onClick={() => handleMenuClick('purchase and payments')}
          >
            <div className={`${activeMenuClass('purchase and payments')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('purchase')}
              >
                <div className="flex space-x-4">
                  <PurchaseIcons height="51" width="37" />
                  <h1 className="flex items-center">Purchase and Payments</h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showPurchase && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.BILLS}`}
                onClick={() =>
                  handleSubMenuClick('bills', 'purchase and payments')
                }
                className={`${activeSubMenuClass('bills')}`}
              >
                Bills
              </DropDownItems>
              <DropDownItems
                to={`${routes.PAYMENTS}`}
                onClick={() =>
                  handleSubMenuClick('paymentSetup', 'purchase and payments')
                }
                className={`${activeSubMenuClass('paymentSetup')}`}
              >
                Payment Set-up
              </DropDownItems>
              <DropDownItems
                to={`${routes.VENODRS}`}
                onClick={() =>
                  handleSubMenuClick('vendors', 'purchase and payments')
                }
                className={`${activeSubMenuClass('vendors')}`}
              >
                Vendors
              </DropDownItems>
              <DropDownItems
                to={`${routes.PURCHASE_PRODUCTS}`}
                onClick={() =>
                  handleSubMenuClick(
                    'productandServices',
                    'purchase and payments'
                  )
                }
                className={`${activeSubMenuClass('productandServices')}`}
              >
                Product and Services
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('accounting')}>
            <div className={`${activeMenuClass('accounting')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('accounting')}
              >
                <div className="flex space-x-4">
                  <AccountingIcons height="51" width="37" />
                  <h1 className="flex items-center">Accounting</h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showAccounting && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.TRANSACTIONS}`}
                onClick={() => handleSubMenuClick('transaction', 'accounting')}
                className={`${activeSubMenuClass('transaction')}`}
              >
                Transactions
              </DropDownItems>
              <DropDownItems
                to={`${routes.RECON}`}
                onClick={() => handleSubMenuClick('recon', 'accounting')}
                className={`${activeSubMenuClass('recon')}`}
              >
                Recon
              </DropDownItems>
              <DropDownItems
                to={`${routes.CHARTOFACCOUNTS}`}
                onClick={() => handleSubMenuClick('charts', 'accounting')}
                className={`${activeSubMenuClass('charts')}`}
              >
                Chart of Accounts
              </DropDownItems>
              <DropDownItems
                to={`${routes.BANK_CONNECTIONS}`}
                onClick={() =>
                  handleSubMenuClick('bankConnection', 'accounting')
                }
                className={`${activeSubMenuClass('bankConnection')}`}
              >
                Bank Connections
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleReportMenu('reports')}>
            <div className={`${activeMenuClass('reports')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('reports')}
              >
                <div className="flex space-x-4">
                  <ReportsIcons height="51" width="37" />
                  <h1 className="flex items-center">Reports</h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showReport && (
            <DropDownContainer>
              <div className="px-2">
                <span
                  className="w-full"
                  onClick={() => handleReportSub('profit')}
                >
                  <div className={`${reportDropDownMenuClass('profit')}`}>
                    <div className="flex space-x-4">
                      <div className="flex">
                        <span className="w-16" />
                        <h1 className="text-xs">Profiit and Loss</h1>
                      </div>
                      <span className="items-center">
                        <DropDownIcon height="12" width="8" />
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </DropDownContainer>
          )}
          {showProfit && (
            <DropDownMenuContainer>
              <DropDownMenuItems
                to={`${routes.COMPARATIVE}`}
                onClick={() => handleProfit('comparative')}
                className={`${activeSubMenuMenuClass('comparative')}`}
              >
                Comparative
              </DropDownMenuItems>
              <DropDownMenuItems
                to={`${routes.QUARTER}`}
                onClick={() => handleProfit('quarter')}
                className={`${activeSubMenuMenuClass('quarter')}`}
              >
                Quarter
              </DropDownMenuItems>
              <DropDownMenuItems
                to={`${routes.YEAR_TO_DATE}`}
                onClick={() => handleProfit('yearToyear')}
                className={`${activeSubMenuMenuClass('yearToyear')}`}
              >
                Year to Date
              </DropDownMenuItems>
              <DropDownMenuItems
                to={`${routes.MONTHLY}`}
                onClick={() => handleProfit('monthly')}
                className={`${activeSubMenuMenuClass('monthly')}`}
              >
                Monthly
              </DropDownMenuItems>
            </DropDownMenuContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('settings')}>
            <div className={`${activeMenuClass('settings')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('settings')}
              >
                <div className="flex space-x-4">
                  <SettingsIcon height="51" width="37" />
                  <h1 className="flex items-center">Setup</h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showSettings && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.SALES_TAX}`}
                onClick={() => handleSubMenuClick('sales tax', 'settings')}
                className={`${activeSubMenuClass('sales tax')}`}
              >
                Sales Tax
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>
      </SideBarContainer>
    </>
  );
};
