export const topRoutes = {
  HOME: '/',
  REVENUE_AND_COLLECTIONS: '/revenue-and-collections',
  INVENTORY: '/inventory',
  PURCHASE_AND_PAYMENTS: '/purchase-and-payments',
  ACCOUNTING: '/accounting',
  REPORTS: '/reports',
  SETTINGS: '/settings',
};

export const routes = {
  HOME: '/',
  REVENUE: `/revenue-and-collections`,
  CHARTOFACCOUNTS: `${topRoutes.ACCOUNTING}/chart-of-accounts`,
  COLLECTION_SETUP: `/revenue-and-collections/collection-set-up`,
  CUSTOMER_STATEMENT: `/revenue-and-collections/customer-statements`,
  RNC_CUSTOMERS: `${topRoutes.REVENUE_AND_COLLECTIONS}/customers`,
  RNC_ESTIMATES: `${topRoutes.REVENUE_AND_COLLECTIONS}/estimates-and-quotes`,
  RNC_INVOICES: `${topRoutes.REVENUE_AND_COLLECTIONS}/invoices`,
  CONTACTS: `/revenue-and-collections/contacts`,
  CURRENCY: `/revenue-and-collections/currency`,
  INVENTORIES: `/inventory`,
  CATEGORIES: `/inventory/categories`,
  INVENTORY_PRODUCTS: `/inventory/products-and-services`,
  PURCHASE: `/purchase-and-payments`,
  BILLS: `/purchase-and-payments/bills`,
  PAYMENTS: `/purchase-and-payments/payment-set-up`,
  VENODRS: `/purchase-and-payments/vendors`,
  PURCHASE_PRODUCTS: `/purchase-and-payments/products-and-services`,
  ACCOUNTING: `/accounting`,
  TRANSACTIONS: `/accounting/transactions`,
  RECON: `/accounting/recon`,
  BANK_CONNECTIONS: `/accounting/bank-connections`,
  REPORTS: `/reports`,
  COMPARATIVE: `/reports/profitandloss/comparative`,
  QUARTER: `/reports/profitandloss/quarter`,
  YEAR_TO_DATE: `/reports/profitandloss/year-to-date`,
  MONTHLY: `/reports/profitandloss/monthly`,
  SETTINGS: `/settings`,
  SALES_TAX: `/settings/sales-taxes`,
};
