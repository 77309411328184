import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';

import { CustomerCreateContext } from './CustomerCreate';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Modal } from '@components/Modal';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import {
  ContactViewModel,
  contactDefaultValue,
  ContactValidation,
} from '@models/Contact';

export const CustomerCreateContactSelect = () => {
  const { customer, setCustomer } = useContext(CustomerCreateContext);
  const [latestContactUiid, setLatestContactUiid] = useState<number>(0);
  const customerContacts = customer.contacts || [];
  const [customerContactSelectOptions, setCustomerContactSelectOptions] =
    useState<FormikSelectOption[]>([]);
  const [isShowAddContact, setIsShowAddContact] = useState(false);
  const [isShowEditContact, setIsShowEditContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<ContactViewModel | null>();

  const loadContactOptions = () => {
    const customerContactSelection = [{ value: '0', text: '' }].concat(
      customerContacts.map((row) => ({
        value: row.uiid.toString(),
        text: `${row.firstname} ${row.lastname}`,
      }))
    );
    setCustomerContactSelectOptions(customerContactSelection);
  };

  useEffect(() => {
    loadContactOptions();
  }, [customerContacts]);

  console.log(customer);

  return (
    <>
      <ActionableSelect
        label="Contact"
        value={customer.contactUiid?.toString()}
        selection={customerContactSelectOptions}
        onAddAction={() => {
          setIsShowAddContact(true);
        }}
        onEditAction={() => {
          if (customer.contactUiid == undefined || customer.contactUiid == 0)
            return;

          const selectedContact = customerContacts.find(
            (row) => row.uiid == customer.contactUiid
          );

          if (selectedContact == undefined) return;

          setContactToEdit(selectedContact);
          setIsShowEditContact(true);
        }}
        onDeleteAction={() => {
          const updatedContacts = customerContacts.filter(
            (o) => o.uiid !== customer.contactUiid
          );
          setCustomer({
            ...customer,
            contactUiid: 0,
            contacts: updatedContacts,
          });
        }}
        onDuplicateAction={() => {
          const selectedContact = customerContacts.find(
            (row) => row.uiid == customer.contactUiid
          );

          if (selectedContact == undefined) return;

          const newContactUiid = latestContactUiid + 1;
          const newContact = {
            ...selectedContact,
            uiid: newContactUiid,
          };

          const updatedContacts = customerContacts.concat(newContact);
          setLatestContactUiid(newContactUiid);
          setCustomer({
            ...customer,
            contactUiid: newContactUiid,
            contacts: updatedContacts,
          });

          setContactToEdit(newContact);
          setIsShowEditContact(true);
        }}
        onChange={(e) => {
          const updateCustomer = {
            ...customer,
            contactUiid: Number(e.target.value),
          };
          setCustomer(updateCustomer);
        }}
      />

      {isShowAddContact && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowAddContact(false)} />

            <Formik
              initialValues={contactDefaultValue}
              validationSchema={ContactValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const newContactUiid = latestContactUiid + 1;
                values.uiid = newContactUiid;

                const updatedContacts = customerContacts.concat(values);
                setLatestContactUiid(newContactUiid);
                setCustomer({
                  ...customer,
                  contactUiid: newContactUiid,
                  contacts: updatedContacts,
                });

                actions.resetForm();
                setIsShowAddContact(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <FormSectionContainer>
                        <FormikInput label="Firstname" name="firstname" />
                        <FormikInput label="Lastname" name="lastname" />
                        <FormikInput label="Email" name="email" />
                        <FormikInput label="Phone" name="phone" />
                        <FormikInput label="Fax" name="fax" />
                      </FormSectionContainer>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowAddContact(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}

      {isShowEditContact && contactToEdit && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowEditContact(false)} />

            <Formik
              initialValues={contactToEdit}
              validationSchema={ContactValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const updatedContacts = customerContacts.map((row) =>
                  row.uiid === values.uiid ? values : row
                );
                setCustomer({
                  ...customer,
                  contactUiid: values.uiid,
                  contacts: updatedContacts,
                });

                actions.resetForm();
                setIsShowEditContact(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <FormSectionContainer>
                        <FormikInput label="Firstname" name="firstname" />
                        <FormikInput label="Lastname" name="lastname" />
                        <FormikInput label="Email" name="email" />
                        <FormikInput label="Phone" name="phone" />
                        <FormikInput label="Fax" name="fax" />
                      </FormSectionContainer>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowEditContact(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};
